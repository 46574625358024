import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../layout'
import SEO from '../../utils/seo'

import ProductsContent from '../../components/Modules/Solutions/ProductsContent'

const SoftwarePage = () => {
    const { softwareData } = useStaticQuery(graphql`
        query {
            softwareData: allStrapiSoftwares {
                edges {
                    node {
                        id
                        categories {
                            description
                            id
                            title
                            background {
                                publicURL
                            }
                            produtos {
                                id
                                name
                                image {
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const mainSoftwareData = softwareData.edges[0].node

    return (
        <Layout>
            <SEO title="Software" />

            <ProductsContent
                title={mainSoftwareData?.categories?.title}
                description={mainSoftwareData?.categories?.description}
                heroBackgroundImage={mainSoftwareData?.categories?.background.publicURL}
                productsData={mainSoftwareData?.categories?.produtos || []}
            />
        </Layout>
    )
}

export default SoftwarePage
